<i18n>
{
  "de": {
    "identifierTitle": "Identifikation",
    "descriptionTitle": "Bezeichnung",
    "zipCodeTitle": "Postleitzahl",
    "municipalityTitle": "Gemeinde",
    "selectAllLabel": "Alle auswählen",
    "selectNoneLabel": "Auswahl entfernen",
    "missingArea": "Fläche fehlt",
    "areaTitle": "EBF",
    "ghgTitle": "GHG-Emissionen",
    "resetAllButton": "GIS-Daten neu abfragen",
    "simulateButton": "neu simulieren",
    "sanityCheckButton": "Problembehebung",
    "addBuildingsButton": "Liegenschaften hochladen",
    "state": {
      "WAITING": "Warte",
      "PENDING": "Aktualisiere...",
      "SUCCESS": "Erfolgreich"
    },
    "returnButton": "Zurück",
    "pageTitle": "Liegenschaften verwalten"
  }
}
</i18n>

<template>
  <main-layout :ready="portfolioReady && portfolioBuildingsReady" class="admin-portfolio-buildings-page">
    <template #default>
      <h1>{{ portfolio.name }} - {{ $t('pageTitle') }}</h1>
      <button-wrapper>
        <div>
          <router-link class="button" :to="{ name: 'adminPortfolioDetails', params: { portfolio_id: portfolio.id }}">{{ $t('returnButton') }}</router-link>
          <router-link class="button" :to="{ name: 'adminPortfolioAddBuildings', params: { portfolio_id: portfolio.id }}">{{ $t('addBuildingsButton') }}</router-link>
          <export-portfolio-buildings :portfolio="portfolio" />
        </div>
      </button-wrapper>
      <br /><br />
      <detail-list class="buildings-table">
        <template #header>
          <span></span>
          <span>{{ $t('identifierTitle') }}</span>
          <span>{{ $t('descriptionTitle') }}</span>
          <span>{{ $t('zipCodeTitle') }}</span>
          <span>{{ $t('municipalityTitle') }}</span>
          <span>{{ $t('areaTitle') }}</span>
          <span>{{ $t('ghgTitle') }}</span>
          <span></span>
          <span></span>
        </template>
        <li v-for="(building, index) in portfolio.buildings" v-bind:key="index">
          <span>
            <check-input v-model="selectedBuildings[index]" :edit="true" />
          </span>
          <span><router-link :to="{ name: 'building', params: { portfolio_id: portfolio.id, building_id: building.id }}">{{ building.identifier }}</router-link></span>
          <span>{{ building.description }}</span>
          <span>{{ building.zip_code }}</span>
          <span>{{ building.municipality }}</span>
          <span>
            <span class="numbers" v-if="building.energy_area">{{ building.energy_area }} m<sup>2</sup></span>
            <span v-else class="missing-data">{{ $t('missingArea') }}</span>
          </span>
          <span><span class="numbers" v-if="building.ghg && building.energy_area">{{ formatNumber(building.ghg / building.energy_area, 0) }}</span><span v-else>-</span> kg/m<sup>2</sup></span>
          <span>
            <div class="gis-errors" v-if="building.gwr_state !== 'OK'">{{ $t(`_gwrState.${building.gwr_state}`) }}</div>
            <div class="gis-errors" v-if="building.gwr_state === 'OK' && building.cadastre_state !== 'OK'">{{ $t(`_cadastreState.${building.cadastre_state}`) }}</div>
            <div class="gis-errors" v-if="building.gwr_state === 'OK' && building.geothermal_state !== 'OK'">{{ $t(`_geothermalState.${building.geothermal_state}`) }}</div>
            <div class="gis-errors" v-if="building.gwr_state === 'OK' && building.energy_grids_state !== 'OK'">{{ $t(`_energyGridsState.${building.energy_grids_state}`) }}</div>
            <div class="gis-errors" v-if="building.gwr_state === 'OK' && building.roof_state !== 'OK'">{{ $t(`_roofState.${building.roof_state}`) }}</div>
          </span>
          <span v-if="buildingState[index]">
            {{ $t(`state.${buildingState[index]}`) }}
          </span>
          <span v-else></span>
        </li>
      </detail-list>
      <a @click="onSelectAll">{{ $t('selectAllLabel') }}</a> /
      <a @click="onSelectNone">{{ $t('selectNoneLabel') }}</a>
      <button-wrapper>
        <button class="button" @click.prevent="onResetAll">{{ $t('resetAllButton') }}</button>
        <button class="button" @click.prevent="onSimulate">{{ $t('simulateButton') }}</button>
        <button class="button" @click.prevent="onSanityCheck">{{ $t('sanityCheckButton') }}</button>
      </button-wrapper>
    </template>
  </main-layout>
</template>

<script>
import MainLayout from '@/pages/layouts/MainLayout'
import AllPortfoliosMixin from '@/pages/vuex-mixins/AllPortfoliosMixin'
import PortfolioMixin from '@/pages/vuex-mixins/PortfolioMixin'
import PortfolioBuildingsMixin from '@/pages/vuex-mixins/PortfolioBuildingsMixin'

import Vue from 'vue'
import compassApi from '@/services/compass-api'
import DetailList from '@/components/shared/lists/DetailList'
import ButtonWrapper from '@/components/shared/ButtonWrapper'
import CheckInput from '@/components/shared/forms/CheckInput'
import ExportPortfolioBuildings from '@/components/admin-portfolios/ExportPortfolioBuildings'

export default {
  mixins: [
    AllPortfoliosMixin, // Provides: allPortfolios, allPortfoliosReady
    PortfolioMixin, // Provides: portfolio, portfolioLoading, portfolioReady
    PortfolioBuildingsMixin // Provides: portfolio.buildings, portfolioBuildingsLoading, portfolioBuildingsReady
  ],
  data () {
    return {
      selectedBuildings: [],
      buildingState: []
    }
  },
  methods: {
    onSelectAll () {
      this.selectedBuildings = this.portfolio.buildings.map(b => true)
    },
    onSelectNone () {
      this.selectedBuildings = this.portfolio.buildings.map(b => false)
    },
    setSelectedPending () {
      this.buildingState = this.selectedBuildings.map(s => s ? 'WAITING' : null)
    },
    async onResetAll () {
      this.setSelectedPending()
      for (let buildingIdx = 0; buildingIdx < this.portfolio.buildings.length; buildingIdx++) {
        if (this.selectedBuildings[buildingIdx]) {
          const building = this.portfolio.buildings[buildingIdx]
          Vue.set(this.buildingState, buildingIdx, 'PENDING')
          await compassApi.call(`/buildings/${building.id}/reset`, 'POST')
          Vue.set(this.buildingState, buildingIdx, 'SUCCESS')
        }
      }
    },
    async onSimulate () {
      this.setSelectedPending()
      for (let buildingIdx = 0; buildingIdx < this.portfolio.buildings.length; buildingIdx++) {
        if (this.selectedBuildings[buildingIdx]) {
          const building = this.portfolio.buildings[buildingIdx]
          Vue.set(this.buildingState, buildingIdx, 'PENDING')
          await compassApi.call(`/buildings/${building.id}/simulate`, 'POST')
          Vue.set(this.buildingState, buildingIdx, 'SUCCESS')
        }
      }
    },
    async onSanityCheck () {
      this.setSelectedPending()
      for (let buildingIdx = 0; buildingIdx < this.portfolio.buildings.length; buildingIdx++) {
        if (this.selectedBuildings[buildingIdx]) {
          const building = this.portfolio.buildings[buildingIdx]
          Vue.set(this.buildingState, buildingIdx, 'PENDING')
          await compassApi.call(`/buildings/${building.id}/sanitycheck`, 'POST')
          Vue.set(this.buildingState, buildingIdx, 'SUCCESS')
        }
      }
    }
  },
  components: {
    MainLayout,
    DetailList,
    CheckInput,
    ButtonWrapper,
    ExportPortfolioBuildings
  }
}
</script>

<style>
.admin-portfolio-buildings-page .detail-list.buildings-table > ul > li {
  grid-template-columns: 32px 1fr 4fr 1fr 2fr 1fr 1fr 4fr 1fr;
}
.admin-portfolio-buildings-page .missing-data {
  color: var(--error-color);
}
.admin-portfolio-buildings-page .unsure-data {
  color: var(--warning-color);
}
</style>
