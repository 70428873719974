<i18n>
{
  "de": {
    "exportBuildings": "JSON-Export Liegenschaften"
  }
}
</i18n>
<template>
  <button-wrapper>
    <button v-if="portfolio" class="button" @click="onExport">{{ $t('exportBuildings') }}</button>
  </button-wrapper>
</template>
<script>

import exportService from '@/services/export-service'
import ButtonWrapper from '@/components/shared/ButtonWrapper'

export default {
  components: {
    ButtonWrapper
  },
  props: {
    portfolio: {
      type: Object,
      required: true
    }
  },
  methods: {
    onExport () {
      exportService.exportPortfolioBuildingsJson(this.portfolio, {})
    }
  }
}
</script>
